import React, { useState } from "react";
import "./App.css";

function App() {
  const [loading, setloading] = useState(false);
  const [message, setmessage] = useState("");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [note, setnote] = useState("");

  const clearFields = () => {
    setname("");
    setemail("");
    setphone("");
    setnote("");
  };

  const sendContact = async () => {
    setmessage("");
    if (!name) {
      setmessage("Name is required");
      return;
    }
    if (!email && !phone) {
      setmessage("Please provide either email or phone number");
      return;
    }
    setloading(true);
    let linkToFetch = `${process.env.REACT_APP_SERVER_LINK}/contact`;
    console.log(linkToFetch);
    let request = await fetch(linkToFetch, {
      method: "POST",
      // credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ name, email, phone, note }),
    }).catch((e) => {
      console.log("Error while fetching:");
      console.log(e);
    });
    let response = await request.json();
    setloading(false);
    if (!response.status) {
      setmessage(response.msg);
    } else {
      setmessage(response.msg);
      clearFields();
    }
  };
  return (
    <div className="form">
      <h2>kontakteq Tyomin</h2>
      <input
        type="text"
        placeholder="Your Name"
        value={name}
        onChange={(e) => setname(e.target.value)}
      />
      <input
        type="text"
        placeholder="Your Email Address"
        value={email}
        onChange={(e) => setemail(e.target.value)}
      />
      <input
        type="text"
        placeholder="Your phone number"
        value={phone}
        onChange={(e) => setphone(e.target.value)}
      />
      <textarea
        placeholder="Notes"
        value={note}
        onChange={(e) => setnote(e.target.value)}
        rows={10}
      ></textarea>
      <div className="row_space_around">
        <button onClick={clearFields}>CLEAR</button>
        {loading ? (
          <button>LOADING...</button>
        ) : (
          <button onClick={sendContact}>Submit</button>
        )}
      </div>

      {message && <p>{message}</p>}
    </div>
  );
}

export default App;
